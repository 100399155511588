import React, { useState } from 'react'
import './style.scss'

import { useForm } from 'react-hook-form'
import axios from 'axios'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBaby } from '@fortawesome/free-solid-svg-icons'

function CF7Basic(props) {
	
    const { register, handleSubmit, formState: { errors, reset } } = useForm()

	const [message, setMessage] = useState(false)
	const [isLoading, setLoading] = useState(false)

	const onSubmit = (form, e) => {
		e.preventDefault()

		setLoading(true)

		// Left is CF7 input name, right is ours
		let data = new FormData()
        data.append('your-name', form.name)
        data.append('your-email', form.email)
		data.append('your-phone', form.phone)
		data.append('your-address', form.address)
        
		const url = 'https://old.deepsleepstudio.com/client/SOLARMY/wp-json/contact-form-7/v1/contact-forms/983/feedback'
		const config = { headers: { 'Content-Type': 'multipart/form-data' } }

		axios.post(url, data, config)
			.then(response => {
					console.log('status', response.data.status)
					console.log('response', response.data)
					if(response.data.message) {
					 setMessage(response.data.message)
					}
					setLoading(false)
					if(response.data.status === 'mail_sent') {
						reset()
					}
				}
			)
			.catch(errors => {
					console.log(errors)
				}
			)
	}

	return (
		<form
			id="consultation-form"
			name="consultation"
			method="post"
			action="/thanks/"
			onSubmit={handleSubmit(onSubmit)}
		>
            	<div className="contact-container flex-12">
                    <label onClick={props?.expand} className="item span-12">
                    <input type="text" placeholder="Enter Your Name" name="name"  {...register("name",{required: false})} />
                        {/* {errors.name && <p className="small margin-off">your first name is required.</p>} */}
                    </label>
                    <label className="item span-12">
                        <input type="text" placeholder="Email" name="email" {...register("email",{required: true, minLength: 6, pattern: /^\S+@\S+$/i})} />
						{errors.email && <p className="small margin-off">a correct email is required.</p>}
                    </label>
					<label className="item span-12">
                        <input type="text" placeholder="Phone Number" name="phone" {...register("phone",{required: false})} />
                        {/* {errors.phone && <p className="small margin-off">a correct phone is required.</p>} */}
                    </label>
                    <label className="item span-12">
                        <input rows="4" placeholder="Address" name="address" {...register("address",{required: false})} />
                    </label>
                </div>
				<button className="orange-button" type="submit"><span>SEND REQUEST{isLoading ? <div className="loader-spinner"><FontAwesomeIcon icon={faBaby} /></div> : null}</span></button>

			<p className="message small">{message}</p>

		</form>
	)
}

export default CF7Basic