import React from 'react'
import './style.scss'
import Contact from '../../../../Header/Contact'

import Map from '../../../../Map/GMap'

function CustomBlock({images: img, files: file, fields: field, wysiwygs: wysiwyg, maps: map}) {

	return (
		<>    
			<div className="content">
				<h2>{field}</h2>
				<div className={`wysiwyg-inject`} dangerouslySetInnerHTML={{__html: wysiwyg}} />
			</div>
		
			<div className="container flex-12">
					<div className="map-container span-8 span-12-mobile">
						<Map {...map[0]} />
					</div>
					<div className="contact-container span-4 span-12-mobile">
						<Contact />
					</div>
			</div>
		</>
	)
}

export default CustomBlock