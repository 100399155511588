import React from 'react'
import './style.scss'

import HeroDetail from './HeroDetail'
import Map from './Map'
import DiscoverCompact from './DiscoverCompact'
import About from './About'
import Steps from './Steps'
import Slider from './Slider'
import HomeSlider from './HomeSlider'
import ExpandForm from './ExpandForm'
import ZipForm from './ZipForm'
import ConsultationForm from './ConsultationForm'
import Work from './Work'
import ScrollAnimation from './ScrollAnimation'
import FeaturedLogos from './FeaturedLogos'
import Benefits from './Benefits'
import Panel from './Panel'

function ACFCustom(props) {
	// Chain Animation
	const delay = props.inheritedDelay

	// Images	
	const images = props.asset?.filter(obj => obj.fieldGroupName === 'page_Pagebuilder_Sections_Content_Block_Custom_Asset_Image')
		.map((node) => {
			return node.assetImg?.localFile.childImageSharp.gatsbyImageData
		})
	
	// Files	
	const files = props.asset?.filter(obj => obj.fieldGroupName === 'page_Pagebuilder_Sections_Content_Block_Custom_Asset_File')
		.map((node) => {
			return node.assetFile
		})

	// Fields	
	const fields = props.asset?.filter(obj => obj.fieldGroupName === 'page_Pagebuilder_Sections_Content_Block_Custom_Asset_Field')
		.map((node) => {
			return node.assetField
		})

	// WYSIWYGS	
	const wysiwygs = props.asset?.filter(obj => obj.fieldGroupName === 'page_Pagebuilder_Sections_Content_Block_Custom_Asset_Wysiwyg')
		.map((node) => {
			return node.assetWysiwyg
		})

	// Maps	
	const maps = props.asset?.filter(obj => obj.fieldGroupName === 'page_Pagebuilder_Sections_Content_Block_Custom_Asset_Map')
		.map((node) => {
			return node.assetMap
		})


	if (props.fieldGroupName === 'page_Pagebuilder_Sections_Content_Block_Custom' && props.anchor === 'custom-hero-detail') {
		return (
			<HeroDetail delay={delay} images={images} files={files} fields={fields} wysiwygs={wysiwygs} maps={maps} />
		)
	}

	if (props.fieldGroupName === 'page_Pagebuilder_Sections_Content_Block_Custom' && props.anchor === 'custom-map') {
		return (
			<Map delay={delay} images={images} files={files} fields={fields} wysiwygs={wysiwygs} maps={maps} />
		)
	}

	if (props.fieldGroupName === 'page_Pagebuilder_Sections_Content_Block_Custom' && props.anchor === 'custom-discover-compact') {
		return (
			<DiscoverCompact delay={delay} images={images} files={files} fields={fields} wysiwygs={wysiwygs} maps={maps} />
		)
	}

	if (props.fieldGroupName === 'page_Pagebuilder_Sections_Content_Block_Custom' && props.anchor === 'custom-about') {
		return (
			<About delay={delay} images={images} files={files} fields={fields} wysiwygs={wysiwygs} maps={maps} />
		)
	}
	
	if (props.fieldGroupName === 'page_Pagebuilder_Sections_Content_Block_Custom' && props.anchor === 'custom-steps') {
		return (
			<Steps delay={delay} images={images} files={files} fields={fields} wysiwygs={wysiwygs} maps={maps} />
		)
	}

	if (props.fieldGroupName === 'page_Pagebuilder_Sections_Content_Block_Custom' && props.anchor === 'custom-slider') {
		return (
			<Slider delay={delay} images={images} files={files} fields={fields} wysiwygs={wysiwygs} maps={maps} />
		)
	}

	if (props.fieldGroupName === 'page_Pagebuilder_Sections_Content_Block_Custom' && props.anchor === 'custom-home-slider') {
		return (
			<HomeSlider delay={delay} images={images} files={files} fields={fields} wysiwygs={wysiwygs} maps={maps} />
		)
	}

	if (props.fieldGroupName === 'page_Pagebuilder_Sections_Content_Block_Custom' && props.anchor === 'custom-expand-form') {
		return (
			<ExpandForm delay={delay} images={images} files={files} fields={fields} wysiwygs={wysiwygs} maps={maps} />
		)
	}

	if (props.fieldGroupName === 'page_Pagebuilder_Sections_Content_Block_Custom' && props.anchor === 'custom-zip-form') {
		return (
			<ZipForm delay={delay} images={images} files={files} fields={fields} wysiwygs={wysiwygs} maps={maps} />
		)
	}

	if (props.fieldGroupName === 'page_Pagebuilder_Sections_Content_Block_Custom' && props.anchor === 'custom-consultation-form') {
		return (
			<ConsultationForm delay={delay} images={images} files={files} fields={fields} wysiwygs={wysiwygs} maps={maps} />
		)
	}

	if (props.fieldGroupName === 'page_Pagebuilder_Sections_Content_Block_Custom' && props.anchor === 'custom-work') {
		return (
			<Work delay={delay} images={images} files={files} fields={fields} wysiwygs={wysiwygs} maps={maps} files={files} />
		)
	}

	if (props.fieldGroupName === 'page_Pagebuilder_Sections_Content_Block_Custom' && props.anchor === 'custom-scroll-animation') {
		return (
			<ScrollAnimation delay={delay} images={images} files={files} fields={fields} wysiwygs={wysiwygs} maps={maps} files={files} />
		)
	}

	if (props.fieldGroupName === 'page_Pagebuilder_Sections_Content_Block_Custom' && props.anchor === 'custom-featured-logos') {
		return (
			<FeaturedLogos delay={delay} images={images} files={files} fields={fields} wysiwygs={wysiwygs} maps={maps} files={files} />
		)
	}

	if (props.fieldGroupName === 'page_Pagebuilder_Sections_Content_Block_Custom' && props.anchor === 'custom-benefits') {
		return (
			<Benefits delay={delay} images={images} files={files} fields={fields} wysiwygs={wysiwygs} maps={maps} files={files} />
		)
	}

	
	if (props.fieldGroupName === 'page_Pagebuilder_Sections_Content_Block_Custom' && props.anchor === 'custom-panel') {
		return (
			<Panel delay={delay} images={images} files={files} fields={fields} wysiwygs={wysiwygs} maps={maps} files={files} />
		)
	}

  return false
}

export default ACFCustom