import React from 'react'
import './style.scss'


function CustomBlock({images: img, files: file, fields: field, wysiwygs: wysiwyg, maps: map}) {

	return (
		<>
		<div className="pixel">
			<div className="container">
				<div className="caption">
					<p>You are here</p>
				</div>
			</div>
		</div>
		<div className="social">
			<p>IG / FB</p>
		</div>
		</>
	)
}

export default CustomBlock