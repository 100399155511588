import React from 'react'
import './style.scss'





function CustomBlock({images: img, files: file, fields: field, wysiwygs: wysiwyg, maps: map}) {

 
    let wysiwygMap = wysiwyg.map( (item,i) => {
        return (
         
            <div key={i} className="single-item">

                <div className="content">
                    <h2>{field[i]}</h2>
                    <div className={`wysiwyg-inject`} dangerouslySetInnerHTML={{__html: item}} ></div>
                </div>
            
            </div>
     
        )
    })
   
  
	return (
  
        <div className="item-container">
          {wysiwygMap}
        </div>
     
	)
}

export default CustomBlock
