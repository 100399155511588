import React, {useState} from 'react'
import './style.scss'
import { GatsbyImage } from 'gatsby-plugin-image'

import Intro from '../../../../../animations/Intro'




function CustomBlock({images: img, files: file, fields: field, wysiwygs: wysiwyg, maps: map}) {

    const [currentSlide, setCurrentSlide] = useState(0)
    
    let slides = wysiwyg.map( (item,i) => {
       
        return (
            
            <div key={i} className={`single-item slide-${i} span-6`}>
                <div className="box">
                    <div className="img-wrap">
                        <div className="aspect-ratio">
                            <GatsbyImage image={img[i]} alt="" />
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className={`wysiwyg-inject`} dangerouslySetInnerHTML={{__html: item}} ></div>
                </div>
            </div>
        )
    })

    // Clicking the dots
	function dotClicked(i) {
		setCurrentSlide(i)
	
	}

    const dots = slides.map((node, i) => {
		return (
			<Intro key={`dots-${i}`} visible={true} in={{ fade: 500 }}  className="c3 t"><li className={`${i === currentSlide ? 'current' : ''}`} onClick={() => dotClicked(i)}></li></Intro>
		)
	})
   
  
	return (
    <>
        <h2 className="text-center header">{field}</h2>
        <div className="slides-container ">
            <Intro switch={currentSlide} visible={true} in={{ bg: 300, fadeTop: 300 }}  out={{ bg: 300, fadeBottom: 300 }} delayIn={100} mounted={true} stay={true} className={``}>
                { slides[currentSlide] }
            </Intro>
            <div className="dots">
                <ul>
                    {dots}
                </ul>
            </div>
        </div>
     </>
	)
}

export default CustomBlock
