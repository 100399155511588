import React, { useState } from 'react'
import './style.scss'

import { useForm } from 'react-hook-form'
import axios from 'axios'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faBaby } from '@fortawesome/free-solid-svg-icons'

function CF7Basic(props) {
	
    const { register, handleSubmit, control, formState: { errors, reset } } = useForm()

	const [message, setMessage] = useState(false)
	const [isLoading, setLoading] = useState(false)

	const onSubmit = (form, e) => {
		e.preventDefault()

		setLoading(true)

		// Left is CF7 input name, right is ours
		let data = new FormData()
        data.append('your-name', form.name)
        data.append('your-email', form.email)
		data.append('your-phone', form.phone)
		data.append('your-address', form.address)
        
		const url = 'https://old.deepsleepstudio.com/client/SOLARMY/wp-json/contact-form-7/v1/contact-forms/239/feedback'
		const config = { headers: { 'Content-Type': 'multipart/form-data' } }

		axios.post(url, data, config)
			.then(response => {
					console.log('status', response.data.status)
					console.log('response', response.data)
					if(response.data.message) {
					 setMessage(response.data.message)
					}
					setLoading(false)
					if(response.data.status === 'mail_sent') {
						reset()
					}
				}
			)
			.catch(errors => {
					console.log(errors)
				}
			)
	}

	return (
		<form
			id="zip-form"
			name="zip"
			method="post"
			action="/thanks/"
			onSubmit={handleSubmit(onSubmit)}
            className="flex-12"
		>
            <div className="contact-container  span-4 span-12-mobile">
                    <label onClick={props?.expand} className="item">
                    <input type="text" placeholder="Enter your zip code" name="zip"  {...register("zip",{required: false})} />
                        {/* {errors.name && <p className="small margin-off">your first name is required.</p>} */}
                    </label>
            </div>
				<button className="btn span-8 span-12-mobile" type="submit"><span>Receive My Quote{isLoading ? <div className="loader-spinner"><FontAwesomeIcon icon={faBaby} /></div> : null}</span></button>

			<p className="message small">{message}</p>

		</form>
	)
}

export default CF7Basic