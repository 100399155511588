import React, {useEffect} from 'react'
import './style.scss'

import CF7Basic from '../../../../Form/CF7/Consultation'


function CustomBlock({images: img, files: file, fields: field, wysiwygs: wysiwyg, maps: map}) {



    useEffect(()=>{
        let targets = document.querySelectorAll(`.contact-container .item`)
        targets.forEach(target => target.style.setProperty('--originalHeight', `${target.scrollHeight}px`))
    }, [])

    const expand = () => {
        document.querySelectorAll(`.contact-container .item`).forEach(item => item.classList.add("expand"))
    }



	return (
    <>
        <div className="expand-form">
            <CF7Basic expand={expand}/>
        </div>
     </>
	)
}

export default CustomBlock
