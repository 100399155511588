import React from 'react'
import './style.scss'

import CF7Basic from '../../../../Form/CF7/Zip'


function CustomBlock({images: img, files: file, fields: field, wysiwygs: wysiwyg, maps: map}) {


	return (
    <>
      <div className="content">
            <div className={`wysiwyg-inject`} dangerouslySetInnerHTML={{__html: wysiwyg}} ></div>
            <div className="form-container">
                <CF7Basic/>
            </div>
        </div>
     </>
	)
}

export default CustomBlock
