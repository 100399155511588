import React, { useState, useEffect, useRef } from 'react'
import { useStaticQuery, graphql} from 'gatsby'
import './style.scss'


function CustomBlock({images: img, files: file, fields: field, wysiwygs: wysiwyg, maps: map}) {

  const data = useStaticQuery(
    graphql`
    query {
      frames : allFile(filter: {relativeDirectory: {eq: "frames"}}
      sort: {fields: name, order: ASC}
      ) {
        nodes {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    `
  ) 

  const ImageCanvas = ({ scrollHeight, numFrames, width, height }) => {
    const canvasRef = useRef(null);
    const [images, setImages] = useState([]);
    const [frameIndex, setFrameIndex] = useState(0);


    function GetCurrentFrame(index) {
      return data.frames.nodes[index]?.childImageSharp.gatsbyImageData.images.fallback.src
    }
  
    function preloadImages() {
      for (let i = 1; i <= numFrames; i++) {
        const img = new Image();
        const imgSrc = GetCurrentFrame(i);
        img.src = imgSrc;
        setImages((prevImages) => [...prevImages, img]);
      }
    }
  
    const handleScroll = () => {
      const scrollFraction = window.scrollY / (scrollHeight - window.innerHeight);
      const header = document.querySelector('.header')
      const bg = document.querySelector('.bg')
      const scrollIcon = document.querySelector('.scroll-container')

      const index = Math.min(
        numFrames - 1,
        Math.ceil(scrollFraction * numFrames)
      );
     

      bg.style.opacity = 1 - (index/5) 

      if(index > 2) {
        scrollIcon.classList.add('remove')
      }
      else {
        scrollIcon.classList.remove('remove')
      }

      if(index > 25) {
        header.classList.add('active')
      }
      else {
        header.classList.remove('active')
      }
    
      if (index <= 0 || index > numFrames) {
        return;
      }
  
      setFrameIndex(index);
    };
  
    const renderCanvas = () => {
      const context = canvasRef.current.getContext("2d");
      context.canvas.width = width;
      context.canvas.height = height;
    };
  
    useEffect(() => {
      preloadImages();
      renderCanvas();
      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }, []);
  
    useEffect(() => {
      if (!canvasRef.current || images.length < 1) {
        return;
      }
  
      const context = canvasRef.current.getContext("2d");
      let requestId;
  
      const render = () => {
        context.drawImage(images[frameIndex], 0, 0);
        requestId = requestAnimationFrame(render);
      };
  
      render();
  
      return () => cancelAnimationFrame(requestId);
    }, [frameIndex, images]);
  
    return (
      <div style={{ height: scrollHeight }}>
        <canvas ref={canvasRef} />
        <h2 className="header">Slim Design</h2>
      </div>
    );
  };
  

	return (
    <div className="main">
      <div className="canvas-container">
          <ImageCanvas
            scrollHeight={4500}
            width={3840}
            height={2160}
            numFrames={59}
          />
          <div className="bg"></div>
      </div>
      <div class="scroll-container">
        <div class="field">
          <div class="scroll">
            <span>Scroll Down</span>
          </div>
        </div>
      </div>
    </div>
	)
}

export default CustomBlock