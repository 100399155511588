import React from 'react'
import './style.scss'

import CF7Basic from '../../../../Form/CF7/Consultation'


function CustomBlock({images: img, files: file, fields: field, wysiwygs: wysiwyg, maps: map}) {


	return (
    <>
    <div className="content">
			<h2>{field}</h2>
			<div className={`wysiwyg-inject`} dangerouslySetInnerHTML={{__html: wysiwyg}} />
		</div>
		
		<div className="container">
      <CF7Basic />
		</div>
    </>
	)
}

export default CustomBlock
