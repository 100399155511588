import React, {useContext} from 'react'
import './style.scss'
import { Link } from 'gatsby'


import ApolloContext from '../../../../../context/ApolloContext'

function CustomBlock({images: img, files: file, fields: field, wysiwygs: wysiwyg, maps: map}) {

    const apollo = useContext(ApolloContext)

    let posts = apollo.data?.compactPosts.nodes.map( (node, i) => {
        return (
            <Link key={i} className="hover span-6 span-12-mobile" to={`/discover${node.uri}`}>
                <div className="single-item">
                    <div className="img-wrapper">
                        <img srcSet={node.featuredImage.node.srcSet} alt="" />
                    </div>
                   
                    <div className="content-container">
                        <h4> {node.title}</h4>
                        <span className='link'>Read More</span>
                    </div>
                </div>
            </Link>
        )
    })
   
  
	return (
     
        <div className="item-container flex-12">
            {posts}
        </div>

	)
}

export default CustomBlock
