import React from 'react'
import './style.scss'

import { GatsbyImage } from 'gatsby-plugin-image'

function CustomBlock({images: img, files: file, fields: field, wysiwygs: wysiwyg, maps: map}) {

    const imagesMap = img.map( (item, i) => {

        return (	
			<div key={i} className="single-item span-3">
				<GatsbyImage image={item}
				imgStyle={{objectFit: 'contain'}}
				objectPosition='50% 50%'
				alt=""
				/>
			</div>
		)
    })
  
	return (
        <div className="flex-12">
			<div className="header span-4 span-12-tablet">
                <div className={`wysiwyg-inject`} dangerouslySetInnerHTML={{__html: wysiwyg[0]}} />
            </div>
            <div className="item-container span-8 span-12-tablet flex-12">
                {imagesMap}
            </div>
		</div>
	)
}

export default CustomBlock