import React from 'react'
import './style.scss'
import { GatsbyImage } from 'gatsby-plugin-image'





function CustomBlock({images: img, files: file, fields: field, wysiwygs: wysiwyg, maps: map}) {

 
    let steps = wysiwyg.map( (item,i) => {
       
        return (
            
            <div key={i} className={`${i % 2 === 0 ? 'c4' : 'c3'} ${i === 2 ? 'order-change' : ''} single-item step-${i} span-6 span-12-mobile`}>
                <div className="box">
                    <div className="number">
                        {i + 1}
                    </div>
                    <div className="img-wrap">
                        <div className="aspect-ratio">
                            <GatsbyImage image={img[i]} alt="" />
                        </div>
                        </div>
                </div>
             
                <div className="content">
                    <div className={`wysiwyg-inject`} dangerouslySetInnerHTML={{__html: item}} ></div>
                </div>
            
            </div>
         
            
     
        )
    })
   
  
	return (
  
        <div className="steps-container flex-12">
          {steps}
        </div>
     
	)
}

export default CustomBlock
