import React, {useState} from 'react'
import './style.scss'


function CustomBlock({images: img, files: file, fields: field, wysiwygs: wysiwyg, maps: map}) {

    const [currentItem, setCurrentItem] = useState(0)

    let fields = field.map( (item,i) => {
        return (
            <li onMouseEnter={() => setCurrentItem(i)} key={i} className="item"><span className={`${ i === currentItem ? "active" : "" } bar`}></span><span className="icon"></span>{item}</li>
        )
    })
   
  
	return (
  
        <div className="container">
            <div className="header">
                <div className={`wysiwyg-inject`} dangerouslySetInnerHTML={{__html: wysiwyg}} ></div>
            </div>
            <ul>
                {fields}
            </ul>
        </div>
     
	)
}

export default CustomBlock
