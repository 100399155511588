import React from 'react'
import './style.scss'
import { GatsbyImage } from 'gatsby-plugin-image'

function CustomBlock({images: img, files: file, fields: field, wysiwygs: wysiwyg, maps: map}) {

  
	return (
  
        <div className="container c5">
            <div className="img-container">
                <div className="aspect-ratio">
                    <GatsbyImage image={img[0]}
                        imgStyle={{objectFit: 'contain'}}
                        objectPosition='50% 50%'
                        alt=""
                    />
                </div>
            </div>
            <div className="pixel">
                <div className="container">
                    <div className="caption">
                        <p>{field[0]}</p>
                    </div>
                </div>
            </div>
            <div className="pixel">
                <div className="container">
                    <div className="caption">
                        <p>{field[1]}</p>
                    </div>
                </div>
            </div>
            <div className="pixel">
                <div className="container">
                    <div className="caption">
                        <p>{field[2]}</p>
                    </div>
                </div>
            </div>
        </div>
     
	)
}

export default CustomBlock
