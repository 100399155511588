import React, {useState, useEffect} from 'react'
import './style.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExpand } from '@fortawesome/free-solid-svg-icons'

function CustomBlock({images: img, files: file, fields: field, wysiwygs: wysiwyg, maps: map, files: fileArr}) {

    const [isPlaying, setIsPlaying] = useState(false)
    const [currentVideo, setCurrentVideo] = useState(-1)

    const fullScreen = () => {
      let video = document.querySelector(`.video-${currentVideo}`)

      if (video.requestFullscreen) 
        video.requestFullscreen();
      else if (video.webkitRequestFullscreen) 
        video.webkitRequestFullscreen();
      else if (video.msRequestFullScreen) 
        video.msRequestFullScreen();
    }

    useEffect(()=>{
        let allVideos = document.querySelectorAll(`video`)
        let video = document.querySelector(`.video-${currentVideo}`)
        allVideos.forEach(video => video.pause())
    
        video?.play()
    
    }, [currentVideo, isPlaying])
	
	function vPlay(i) {
        if(i === currentVideo)
            setCurrentVideo(-1)
        else {
            setCurrentVideo(i)
        }
        
        setIsPlaying(!isPlaying)
	}

    let videos = fileArr.map((file,i) => {
        return (
            <div key={i} className={`video span-4 span-12-mobile`}>
                <div className={`video-html-wrap `}>
                    <div onClick={() =>{ vPlay(i)} } className={`icon-container`}>
                        <div onClick={() =>{ vPlay(i)} }  className={`play-button ${i === currentVideo ? 'current' : ""}`}></div>
                    </div>
                    <video className={`video-${i}`} loop playsInline disablePictureInPicture allow="autoplay">
                        <source src={file.localFile.publicURL} type="video/mp4" />
                        <track
                        default kind="captions"
                        srcLang="en"
                        src="none.vtt"/>
                    </video>
                    <FontAwesomeIcon className={currentVideo === i ? "" : "hidden"}  onClick={() =>{ fullScreen()} } icon={faExpand} />
                </div>
                <p className="title">Title</p>
            </div>
        )
    })
	return (
		<>    
			<div className="content">
				<h2>{field}</h2>
				<div className={`wysiwyg-inject`} dangerouslySetInnerHTML={{__html: wysiwyg}} />
			</div>
		
			<div className="videos-container c5 flex-12">
				{videos}
			</div>
		</>
	)
}

export default CustomBlock